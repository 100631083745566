body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.linkListIcon {
  width:64px;
  height:64px;
  margin:10px;
  background-color: silver;
  float:left;
}

.sitelogo {
  width: 400px;
  max-width: 90vw;
  margin-top:20px;
}

.hidden {
  display: none !important;
}


.address-bar {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  width: 100%;
  padding: 10px;
}

.address-bar button {
  margin: 0 5px;
  padding: 5px 10px;
}

.address-bar form {
  flex: 1;
}
.address-bar form input {
  width:100%;
  height: 30px;
  padding: 5px;
}

.text-monospace { font-family: monospace, monospace; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
